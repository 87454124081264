<template>
  <div>
    <h2>Change Password</h2>
    <hr />
    <div>
      <div>
        <!--UserName-->
        <div class="p-field p-grid p-fluid">
          <label for="name" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
            Username
          </label>
          <div class="p-col-12 p-md-10">
            <InputText :value="getUserName" disabled />
          </div>
        </div>
        <!--Nama Website-->
        <div class="p-field p-grid p-fluid">
          <label for="name" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
            Change Password
          </label>
          <div class="p-col-12 p-md-10">
            <Password v-model="new_password" toggleMask />
          </div>
        </div>
        <Divider />
      </div>
      
      <div class="p-d-flex p-jc-end">
        <Button label="Update" @click="updatePassword" />
      </div>
      <!-- <div>Icons made by <a href="https://www.freepik.com" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div> -->
    </div>
    <Toast/>
  </div>
</template>

<script>
export default {
  data() {
    return {
      new_password: null,
    };
  },
  computed: {
    getUserName() {
      return this.$store.state.username;
    },
    getUserId() {
      return this.$store.state.userId;
    },
  },
  methods: {
    async updatePassword() {
      await this.$http
        .post("/updatePassword/" + this.getUserId, {
          name:this.getUserName,
          password: this.new_password,
        })
        .then((r) => {
          console.log(r)
          if (r.status == 200) {
            this.new_password="";
            this.$toast.add({
              severity: "info",
              summary: "Confirmed",
              detail: "Password Updated",
              life: 3000,
            });
          }
        });
    },
  },
  mounted() {
    this.$http.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
  },
};
</script>

<style>
</style>